import { eventsGet } from "../actions";
import { IEventTickets } from "../interfaces";

import { Box, Typography, MenuItem } from "@mui/material";
import useRequest from "api/useRequest";
import trans from "helpers/trans";
import usePermission from "hooks/usePermission";
import { FC, useMemo, useState } from "react";
import {
    Modal,
    ModalTitle,
    ModalContent,
    Loader,
    SettingsItem,
    Select,
    Settings,
    NumberField,
    ModalActions,
    Button,
    RequestMessage,
} from "ui";

interface ITicketsSelectModal {
    title: string;
    handleChangeSearch: Function;
    onClickTicket: Function;
    onCloseModal: Function;
    id: number;
}
const TicketsSelectModal: FC<ITicketsSelectModal> = ({
    title,
    onClickTicket,
    onCloseModal,
    id,
}) => {
    const permission = usePermission("Events", "read");

    const lang = document.documentElement.lang;
    const [amount, setAmount] = useState<number>(0);
    const [ticket, setTicket] = useState<number>(0);
    const { data, isPending, isLoading, isError, message, status } = useRequest(
        eventsGet(id, {
            params: {
                _with: ["subtranslations", "event_tickets.subtranslations"],
            },
        })
    );

    const ticketsList = useMemo(() => {
        if (!data?.data) return [];
        return data.data.event_tickets;
    }, [data]);

    const renderContent = () => {
        if (!permission) return null;
        if (isLoading || isPending) return <Loader />;
        if (isError)
            return (
                <RequestMessage
                    message={message}
                    status={status}
                    sx={{ mt: 2 }}
                />
            );
        if (ticketsList.length === 0)
            return (
                <Typography variant="body1" sx={{ textAlign: "center", mb: 2 }}>
                    {trans("event.events.modal.ticketsSelectModal.notFound")}
                </Typography>
            );
        return (
            <Settings>
                <SettingsItem
                    label={trans(
                        "event.events.modal.ticketsSelectModal.label.ticket"
                    )}
                >
                    <Select
                        fullWidth
                        id="tickets"
                        onChange={(e: { id: string; value: number }) =>
                            setTicket(e.value)
                        }
                        value={ticket}
                    >
                        {ticketsList.map((item: IEventTickets) => {
                            return (
                                <MenuItem key={`${item.id}`} value={item.id}>
                                    {
                                        item.subtranslations.name[
                                            `lang_${lang}` as keyof typeof item.subtranslations.name
                                        ]
                                    }
                                </MenuItem>
                            );
                        })}
                    </Select>
                </SettingsItem>
                <SettingsItem
                    label={trans(
                        "event.events.modal.ticketsSelectModal.label.amount"
                    )}
                >
                    <NumberField
                        id="amount"
                        fullWidth
                        value={amount}
                        onChange={(e: { id: string; value: number }) =>
                            setAmount(e.value)
                        }
                    />
                </SettingsItem>
            </Settings>
        );
    };

    return (
        <Modal
            open
            maxWidth="xs"
            fullWidth
            PaperProps={{ sx: { position: "fixed", top: 0 } }}
        >
            <ModalTitle onClose={() => onCloseModal()}>{title}</ModalTitle>
            <ModalContent sx={{ pt: 0, px: 2 }}>{renderContent()}</ModalContent>
            <ModalActions>
                <Box display="flex" width="100%" textAlign="right">
                    <Button
                        onClick={() => onCloseModal()}
                        color="inherit"
                        variant="text"
                        sx={{ mr: 1 }}
                    >
                        {trans("global.button.cancel")}
                    </Button>
                    <Button
                        sx={{ flex: 1 }}
                        onClick={() =>
                            onClickTicket(
                                ticketsList.find(
                                    (t: IEventTickets) => t.id === ticket
                                ),
                                amount
                            )
                        }
                        disabled={ticket === 0 || amount === 0}
                    >
                        {trans("global.button.save")}
                    </Button>
                </Box>
            </ModalActions>
        </Modal>
    );
};

export default TicketsSelectModal;
