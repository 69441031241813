import { UpsaleModel } from "app/Upsales/interfaces";
import { webEventGet } from "../actions";
import WebEventLoader from "./WebEventLoader";

import {
    Box,
    Checkbox,
    Chip,
    Divider,
    FormControl,
    FormControlLabel,
    FormLabel,
    Icon,
    IconButton,
    Radio,
    RadioGroup,
    Stack,
    Typography,
    useMediaQuery,
} from "@mui/material";
import useRequest from "api/useRequest";
import trans from "helpers/trans";
import { isEmpty } from "lodash";
import moment from "moment";
import { FC, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { ModalContent, ModalTitle, RequestMessage } from "ui";
import { IWebUpsale } from "../interfaces";

interface ITicketSecondStep {
    id: number;
    guests: number;
    onSelectTicket: Function;
    onSelectUpsale: Function;
    children: React.ReactNode;
    onCloseModal: Function;
    bookingMessage: string;
    bookingStatus: number;
    selectedUpsales: any;
}

const TicketSecondStep: FC<ITicketSecondStep> = ({
    bookingMessage,
    bookingStatus,
    children,
    id,
    guests,
    onSelectTicket,
    onSelectUpsale,
    onCloseModal,
    selectedUpsales,
}) => {
    const lang = document.documentElement.lang;
    const currency = useSelector(
        (state: any) => state.web.settings.location_settings["general-currency"]
    );
    const smallScreen = useMediaQuery((theme: any) =>
        theme.breakpoints.down("tablet")
    );
    const [selectedTicket, setSelectedTicket] = useState<any>({});
    const { data, isLoading, message, status, isError } = useRequest(
        webEventGet(id)
    );

    const preparedData = useMemo(() => {
        if (!data?.data) return null;
        const format = "YYYY-MM-DD HH:mm:ss";
        const utcTime = moment.utc(data.data.start_datetime, format);
        return {
            ...data.data,
            description:
                data.data.subtranslations.description[
                    `lang_${lang}` as keyof typeof data.data.subtranslations.description
                ],
            title: data.data.subtranslations.title[
                `lang_${lang}` as keyof typeof data.data.subtranslations.title
            ],
            location:
                data.data.subtranslations.location[
                    `lang_${lang}` as keyof typeof data.data.subtranslations.location
                ],
            localDateTime: moment(utcTime)
                .local()
                .locale(lang === "en" ? "en-gb" : lang)
                .format("dddd, DD.MM.YYYY, HH:mm"),
        };
    }, [data, lang]);

    const handleSelectTicket = (ticket: any) => {
        setSelectedTicket(ticket);
        onSelectTicket(ticket);
    };
    const handleSelectUpsale = (
        e: React.ChangeEvent<HTMLInputElement>,
        upsale: any
    ) => {
        onSelectUpsale(e.target.checked, { ...upsale, quantity: 1 });
    };

    const handleUpsaleAddQuantity = (upsaleId: number) => {
        const upsaleToUpdate = selectedUpsales.find(
            (item: UpsaleModel) => item.id === upsaleId
        );
        const upsaleQuantity = upsaleToUpdate?.quantity;
        onSelectUpsale(true, {
            ...upsaleToUpdate,
            quantity: upsaleQuantity + 1,
        });
    };

    const handleUpsaleRemoveQuantity = (upsaleId: number) => {
        const upsaleToUpdate = selectedUpsales.find(
            (item: UpsaleModel) => item.id === upsaleId
        );
        const upsaleQuantity = upsaleToUpdate?.quantity;
        if (upsaleQuantity === 1) onSelectUpsale(false, upsaleToUpdate);
        else
            onSelectUpsale(true, {
                ...upsaleToUpdate,
                quantity: upsaleQuantity - 1,
            });
    };

    useEffect(() => {
        if (preparedData && isEmpty(selectedTicket)) {
            handleSelectTicket(
                preparedData?.event_tickets.find(
                    (ticket: any) => guests <= ticket.current_available
                ) || {}
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [preparedData]);

    useEffect(() => {
        if (preparedData && selectedTicket.current_available < guests) {
            handleSelectTicket(
                preparedData?.event_tickets.find(
                    (ticket: any) => guests <= ticket.current_available
                ) || {}
            );
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [guests, preparedData]);

    const renderUpsalesQuantity = (upsaleId: number) => {
        const isSelected = selectedUpsales.find(
            (item: UpsaleModel) => item.id === upsaleId
        );
        if (!isSelected) return null;
        const quantity = selectedUpsales.find(
            (upsale: UpsaleModel) => upsale.id === upsaleId
        )?.quantity;
        return (
            <Box display="flex" justifyContent="start" alignItems="center">
                <IconButton
                    onClick={() => handleUpsaleRemoveQuantity(upsaleId)}
                    sx={{
                        ":hover": {
                            boxShadow: "none",
                        },
                        width: { xs: 24, tablet: 28 },
                        height: { xs: 24, tablet: 28 },
                        "&.Mui-disabled": {
                            backgroundColor: "rgb(185 121 100 / 0.26)",
                        },
                    }}
                >
                    <Icon sx={{ fontSize: { xs: 16, tablet: 18 } }}>
                        remove
                    </Icon>
                </IconButton>
                <Typography
                    sx={{
                        fontSize: { xs: 14, tablet: 16 },
                        fontWeight: 500,
                        px: 2,
                        fontFamily: "'Noto Sans', sans-serif",
                        color: {
                            xs: "#495366",
                            tablet: "#02263A",
                        },
                        width: "auto !important",
                        minWidth: 55,
                        textAlign: "center",
                    }}
                >
                    {quantity}
                </Typography>

                <IconButton
                    onClick={() => handleUpsaleAddQuantity(upsaleId)}
                    sx={{
                        ":hover": {
                            boxShadow: "none",
                        },
                        width: { xs: 24, tablet: 28 },
                        height: { xs: 24, tablet: 28 },
                        "&.Mui-disabled": {
                            backgroundColor: "rgb(185 121 100 / 0.26)",
                        },
                    }}
                >
                    <Icon sx={{ fontSize: { xs: 16, tablet: 18 } }}>add</Icon>
                </IconButton>
            </Box>
        );
    };

    if (isLoading)
        return (
            <Box position="relative" sx={{ height: "100%" }}>
                <WebEventLoader />
            </Box>
        );

    if (isError) {
        return (
            <>
                {" "}
                <ModalTitle
                    onClose={() => onCloseModal()}
                    sx={{
                        pl: 2,
                        pt: 1,
                        pr: 1,
                        pb: { xs: 2, tablet: 0 },
                        ".MuiTypography-root": {
                            color: "#495366",
                            fontFamily: "'Lato', sans-serif",
                            fontSize: 18,
                            fontWeight: 700,
                        },
                    }}
                />
                <ModalContent
                    sx={{
                        pt: 0,
                        px: { xs: 2, tablet: 4 },
                        display: "flex",
                        flexDirection: "column",
                        pb: 0,
                    }}
                >
                    <RequestMessage
                        message={message}
                        status={status}
                        sx={{
                            width: "100% !important",
                            "&.MuiPaper-root": {
                                height: "fit-content",
                                mb: 2,
                            },
                        }}
                    />
                </ModalContent>
            </>
        );
    }
    if (!preparedData) return null;

    return (
        <>
            <ModalTitle
                onClose={() => onCloseModal()}
                sx={{
                    pl: 2,
                    pt: 1,
                    pr: 1,
                    pb: { xs: 1.5, tablet: 0 },
                    ".MuiTypography-root": {
                        color: "#495366",
                        fontFamily: "'Lato', sans-serif",
                        fontSize: 18,
                        fontWeight: 700,
                    },
                }}
            >
                {smallScreen && preparedData.title}
            </ModalTitle>
            <ModalContent
                sx={{
                    pt: 0,
                    px: { xs: 2, tablet: 4 },
                    display: "flex",
                    flexDirection: "column",
                    pb: 0,
                }}
            >
                <RequestMessage
                    message={bookingMessage}
                    status={bookingStatus}
                    sx={{
                        width: "100% !important",

                        "&.MuiPaper-root": {
                            height: "fit-content",
                            mb: 2,
                        },
                    }}
                />
                <Box>
                    <Typography
                        sx={{
                            fontFamily: "'Lato', sans-serif",
                            fontSize: { xs: 16, tablet: 20 },
                            color: { xs: "#495366", tablet: "#000" },
                            fontWeight: 700,
                            textAlign: "center",
                            mb: { xs: 1.5, tablet: 2.5 },
                        }}
                    >
                        {trans(
                            `event.webEvent.modal.ticketModal.title.chooseType`
                        )}
                    </Typography>

                    <Stack
                        divider={
                            <Divider
                                orientation="horizontal"
                                flexItem
                                sx={{ my: { xs: 2, tablet: 2.5 } }}
                            />
                        }
                    >
                        <Stack spacing={1.25}>
                            <Typography
                                sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                    fontFamily: "'Noto Sans', sans-serif",
                                    fontSize: { xs: 14, tablet: 16 },
                                    fontWeight: 500,
                                    color: { xs: "#495366", tablet: "#02263A" },
                                }}
                            >
                                <Icon
                                    sx={{
                                        fontSize: { xs: 12, tablet: 16 },
                                        color: "#407BFF",
                                        mr: 1,
                                    }}
                                >
                                    circle
                                </Icon>
                                {preparedData.localDateTime}
                            </Typography>
                            {!smallScreen && (
                                <Typography
                                    sx={{
                                        fontFamily: "'Lato', sans-serif",
                                        fontSize: 18,
                                        color: "#02263A",
                                        fontWeight: 700,
                                        textAlign: "left",
                                    }}
                                >
                                    {preparedData.title}
                                </Typography>
                            )}
                        </Stack>
                        {children}
                    </Stack>
                </Box>
                <Box
                    sx={{
                        flex: 1,
                        minHeight: "90px",
                        overflowY: "auto",
                        overflowX: "hidden",
                        minWidth: "fit-content",
                        mt: { xs: 2, tablet: 2.5 },
                        p: 0,
                        gap: { xs: 2, tablet: 2.5 },
                        display: "flex",
                        flexDirection: "column",
                    }}
                >
                    <FormControl sx={{ width: "100%" }}>
                        <FormLabel
                            sx={{
                                fontFamily: "'Lato', sans-serif",
                                fontSize: { xs: 16, tablet: 18 },
                                fontWeight: 700,
                                textAlign: "left",
                                color: { xs: "#495366", tablet: "#02263A" },
                                mr: 0,
                                pb: 1,
                                "&.Mui-focused": { color: "black" },
                            }}
                        >
                            {trans(
                                `event.webEvent.modal.ticketModal.title.chooseType`
                            )}
                        </FormLabel>
                        <RadioGroup sx={{ gap: { xs: 0, tablet: 0.5 } }}>
                            {preparedData.event_tickets.map((ticket: any) => {
                                return (
                                    <FormControlLabel
                                        key={`ticket-${ticket.id}`}
                                        value={ticket.id}
                                        control={
                                            <Radio
                                                size="small"
                                                checked={
                                                    selectedTicket.id ===
                                                    ticket.id
                                                }
                                                disabled={
                                                    guests >
                                                        ticket.current_available ||
                                                    ticket.current_available ===
                                                        0
                                                }
                                                value={ticket.id}
                                                disableRipple
                                                onChange={(e) =>
                                                    handleSelectTicket(ticket)
                                                }
                                            />
                                        }
                                        sx={{
                                            width: "100%",
                                            mr: 0,
                                            color: "#495366",
                                            ".MuiTypography-root": {
                                                width: "100%",
                                                fontSize: {
                                                    xs: 14,
                                                    tablet: 16,
                                                },
                                            },
                                        }}
                                        label={
                                            <Box
                                                sx={{
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    flexWrap: "nowrap",
                                                    justifyContent:
                                                        "space-between",
                                                    alignItems: "center",
                                                    gap: 1.5,
                                                    rowGap: 1,
                                                    width: "100%",
                                                    fontFamily:
                                                        "'Noto Sans', sans-serif",
                                                    fontSize: {
                                                        xs: 14,
                                                        tablet: 16,
                                                    },
                                                    fontWeight: 500,
                                                }}
                                            >
                                                <Box
                                                    sx={{
                                                        wordBreak: "break-word",
                                                        color: {
                                                            xs: "#495366",
                                                            tablet: "#02263A",
                                                        },
                                                        width: "calc(100%/3 - 24px)",
                                                        minWidth: "120px",
                                                    }}
                                                >
                                                    {
                                                        ticket.subtranslations
                                                            ?.name[
                                                            `lang_${lang}`
                                                        ]
                                                    }
                                                </Box>
                                                <Box
                                                    sx={{
                                                        minWidth: "132px",
                                                    }}
                                                >
                                                    <Chip
                                                        sx={{
                                                            height: 28,
                                                            minWidth:
                                                                "fit-content",
                                                            fontFamily:
                                                                "'Noto Sans'",
                                                            fontWeight: 500,
                                                            fontSize: 12,
                                                            backgroundColor:
                                                                guests >
                                                                    ticket.current_available ||
                                                                ticket.current_available ===
                                                                    0
                                                                    ? "#FFC3BB66"
                                                                    : "#D9F0E4",
                                                            color:
                                                                guests >
                                                                    ticket.current_available ||
                                                                ticket.current_available ===
                                                                    0
                                                                    ? "#650000"
                                                                    : "#054808",
                                                        }}
                                                        icon={
                                                            <Icon
                                                                sx={{
                                                                    ml: "8px !important",
                                                                    fontSize: 20,
                                                                    color:
                                                                        guests >
                                                                            ticket.current_available ||
                                                                        ticket.current_available ===
                                                                            0
                                                                            ? `#970000 !important`
                                                                            : `#007D39 !important`,
                                                                }}
                                                            >
                                                                {guests >
                                                                    ticket.current_available ||
                                                                ticket.current_available ===
                                                                    0
                                                                    ? "highlight_off"
                                                                    : "check_circle_outline"}
                                                            </Icon>
                                                        }
                                                        label={
                                                            guests >
                                                                ticket.current_available ||
                                                            ticket.current_available ===
                                                                0
                                                                ? trans(
                                                                      "event.webEvent.modal.ticketModal.soldOut"
                                                                  )
                                                                : trans(
                                                                      "event.webEvent.modal.ticketModal.available",
                                                                      {
                                                                          available:
                                                                              ticket.current_available,
                                                                      }
                                                                  )
                                                        }
                                                    />
                                                </Box>
                                                <Box
                                                    sx={{
                                                        display: "flex",
                                                        flex: 1,
                                                        justifyContent: "end",
                                                        color: {
                                                            xs: "#495366",
                                                            tablet: "#02263A",
                                                        },
                                                        whiteSpace: "nowrap",
                                                    }}
                                                >
                                                    {ticket.price.price(
                                                        currency,
                                                        "left"
                                                    )}
                                                </Box>
                                            </Box>
                                        }
                                    />
                                );
                            })}
                        </RadioGroup>
                    </FormControl>
                    {preparedData.upsales.length > 0 && <Divider sx={{}} />}
                    {preparedData.upsales.length > 0 && (
                        <FormControl sx={{ width: "100%" }}>
                            <FormLabel
                                sx={{
                                    fontFamily: "'Lato', sans-serif",
                                    fontSize: { xs: 16, tablet: 18 },
                                    fontWeight: 700,
                                    textAlign: "left",
                                    pb: 1,
                                    color: { xs: "#495366", tablet: "#02263A" },
                                    mr: 0,
                                    "&.Mui-focused": { color: "black" },
                                }}
                            >
                                {trans(
                                    "event.webEvent.modal.ticketModal.title.upsales"
                                )}
                            </FormLabel>
                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: { xs: 0, tablet: 0.5 },
                                }}
                            >
                                {preparedData.upsales.map((upsale: any) => {
                                    const selected = selectedUpsales.find(
                                        (item: IWebUpsale) =>
                                            item.id === upsale.id
                                    );
                                    return (
                                        <FormControlLabel
                                            key={`upsale-${upsale.id}`}
                                            value={upsale.id}
                                            control={
                                                <Checkbox
                                                    onChange={(e) =>
                                                        handleSelectUpsale(
                                                            e,
                                                            upsale
                                                        )
                                                    }
                                                    checked={Boolean(selected)}
                                                    size="small"
                                                    disableRipple
                                                />
                                            }
                                            sx={{
                                                width: "100%",
                                                mr: 0,
                                                color: "#495366",
                                                ".MuiTypography-root": {
                                                    width: "100%",
                                                },
                                            }}
                                            label={
                                                <Box
                                                    sx={{
                                                        display: "flex",
                                                        flexDirection: "row",
                                                        flexWrap: "wrap",
                                                        justifyContent:
                                                            "space-between",
                                                        alignItems: "center",
                                                        gap: 1,
                                                        width: "100%",
                                                        fontFamily:
                                                            "'Noto Sans', sans-serif",
                                                        fontSize: {
                                                            xs: 14,
                                                            tablet: 16,
                                                        },
                                                        fontWeight: 500,
                                                        color: {
                                                            xs: "#495366",
                                                            tablet: "#02263A",
                                                        },
                                                    }}
                                                >
                                                    <Box
                                                        sx={{
                                                            color: {
                                                                xs: "#495366",
                                                                tablet: "#02263A",
                                                            },
                                                            width: "calc(100%/3 - 24px)",
                                                            minWidth: "120px",
                                                            wordBreak:
                                                                "break-word",
                                                        }}
                                                    >
                                                        {
                                                            upsale
                                                                ?.subtranslations
                                                                .name[
                                                                `lang_${lang}`
                                                            ]
                                                        }
                                                    </Box>
                                                    <Box
                                                        onClick={(e) =>
                                                            e.preventDefault()
                                                        }
                                                        sx={{
                                                            minWidth: "132px",
                                                        }}
                                                    >
                                                        {renderUpsalesQuantity(
                                                            upsale.id
                                                        )}
                                                    </Box>
                                                    <Box
                                                        onClick={(e) =>
                                                            e.preventDefault()
                                                        }
                                                        sx={{
                                                            display: "flex",
                                                            flex: 1,
                                                            justifyContent:
                                                                "end",
                                                            color: {
                                                                xs: "#495366",
                                                                tablet: "#02263A",
                                                            },
                                                            whiteSpace:
                                                                "nowrap",
                                                        }}
                                                    >
                                                        {upsale.price.price(
                                                            currency,
                                                            "left"
                                                        )}
                                                    </Box>
                                                </Box>
                                            }
                                        />
                                    );
                                })}
                            </Box>
                        </FormControl>
                    )}
                </Box>
                <Divider sx={{ mt: 4 }} />
            </ModalContent>
        </>
    );
};
export default TicketSecondStep;
