import { FC, useEffect, useMemo, useState } from "react";
import { Divider, Box, Typography } from "@mui/material";
import {
    Modal,
    ModalTitle,
    ModalContent,
    RequestMessage,
    Search,
    Loader,
} from "ui";
import { List, ListItem, ListItemText } from "@mui/material";
import trans from "helpers/trans";
import useRequest from "api/useRequest";
import { upsalesFetchAll } from "../actions";
import { IEventUpsale } from "app/Events/interfaces";
import { UpsaleModel } from "../interfaces";

interface IUpsalesSelectModal {
    selectedUpsales: IEventUpsale[];
    title: string;
    handleChangeSearch: Function;
    search: string;
    onClickUpsale: Function;
    onCloseModal: Function;
}
const UpsalesSelectModal: FC<IUpsalesSelectModal> = ({
    selectedUpsales,
    title,
    search,
    onClickUpsale,
    onCloseModal,
}) => {
    const [searchVal, setSearchValue] = useState<string>(search);
    const lang = document.documentElement.lang;

    const { currentData, message, status, isError, isLoading, reload } =
        useRequest(
            upsalesFetchAll({
                params: {
                    _search: searchVal,
                    _sort: { "subtranslations.name": "asc" },
                    _with: "subtranslations",
                },
            })
        );
    const upsalesList = useMemo(() => {
        if (!currentData) return [];
        return currentData.data.filter(
            (item: UpsaleModel) =>
                !selectedUpsales.some((upsale: IEventUpsale) => upsale.id === item.id)
        );
    }, [currentData, selectedUpsales]);

    useEffect(() => {
        if (searchVal.length > 0) {
            reload();
        } else {
            setSearchValue("");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchVal]);

    const renderContent = () => {
        if (isLoading) return <Loader />;
        if (isError)
            return status === 404 ? (
                <Typography variant="body1" sx={{ textAlign: "center", mb: 2 }}>
                    {trans("event.upsales.modal.upsalesSelectModal.notFound")}
                </Typography>
            ) : (
                <RequestMessage status={status} message={message} />
            );
        if (upsalesList.length === 0)
            return (
                <Typography variant="body1" sx={{ textAlign: "center", mb: 2 }}>
                    {trans("event.upsales.modal.upsalesSelectModal.notFound")}
                </Typography>
            );
        return (
            <List component="nav" sx={{ pt: 0 }}>
                {upsalesList.map((item: UpsaleModel) => {
                    return (
                        <Box key={`${item.id}`}>
                            <ListItem
                                button
                                onClick={() => onClickUpsale(item)}
                            >
                                <Box
                                    sx={{
                                        flexGrow: 1,
                                    }}
                                >
                                    <ListItemText>
                                        {
                                            item.subtranslations.name[
                                                `lang_${lang}` as keyof typeof item.subtranslations.name
                                            ]
                                        }
                                    </ListItemText>
                                </Box>
                            </ListItem>
                            <Divider />
                        </Box>
                    );
                })}
            </List>
        );
    };

    return (
        <Modal
            open
            maxWidth="xs"
            fullWidth
            PaperProps={{ sx: { position: "fixed", top: 0 } }}
        >
            <ModalTitle onClose={() => onCloseModal()}>{title}</ModalTitle>
            <ModalContent sx={{ pt: 0, px: 2 }}>
                <Search
                    onChange={(val: string) => setSearchValue(val)}
                    value={searchVal}
                    open={true}
                    inputSX={{ width: "100%" }}
                    inputProps={{
                        disabled: upsalesList.length === 0,
                    }}
                />
                {renderContent()}
            </ModalContent>
        </Modal>
    );
};

export default UpsalesSelectModal;
