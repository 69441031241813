import {
    Box,
    Icon,
    IconButton,
    Stack,
    Typography,
    BoxProps,
} from "@mui/material";
import React from "react";
import { SX } from "types/SX";

interface IGPModalTitle extends BoxProps {
    onClose?: (event: React.MouseEvent) => void;
    iconSx?: SX;
}

function GPModalTitle(props: IGPModalTitle) {
    const { children, iconSx, onClose, ...restProps } = props;

    return (
        <Box
            sx={{
                pl: 2,
                pt: 1,
                pr: 1,
                ...restProps.sx,
            }}
            {...restProps}
        >
            <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
            >
                <Typography variant="h6">{children}</Typography>

                {!!onClose && (
                    <IconButton
                        aria-label="close"
                        onClick={onClose}
                        sx={{
                            alignSelf: "start",
                            ...iconSx,
                        }}
                    >
                        <Icon>close</Icon>
                    </IconButton>
                )}
            </Stack>
        </Box>
    );
}

export default GPModalTitle;
