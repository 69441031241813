import types from "./types";
import { request } from "api/actions";
import { AxiosRequestConfig } from "axios";
import { WebBookingModel } from "./interfaces";

export const webEventsFetch = (config?: AxiosRequestConfig) =>
    request(
        types.FETCH_REQUEST,
        types.FETCH_FAILED,
        types.FETCH_SUCCESS,
        "webEventsFetch",
        {
            config,
        }
    );

export const webEventsFetchPayments = (config?: AxiosRequestConfig) =>
    request(
        types.FETCH_PAYMENTS_REQUEST,
        types.FETCH_PAYMENTS_FAILED,
        types.FETCH_PAYMENTS_SUCCESS,
        "webEventsFetchPayments",
        {
            config,
        }
    );

export const webEventGet = (
    id: number,
    config?: AxiosRequestConfig,
    onResponse?: Function
) =>
    request(
        types.GET_REQUEST,
        types.GET_FAILED,
        types.GET_SUCCESS,
        "webEventGet",
        {
            url: {
                id,
            },
            config,
        },
        onResponse
    );

export const webEventsSettings = (
    id: string,
    config?: AxiosRequestConfig,
    onResponse?: Function
) =>
    request(
        types.SETTINGS_REQUEST,
        types.SETTINGS_FAILED,
        types.SETTINGS_SUCCESS,
        "webEventsSettings",
        {
            url: {
                id,
            },
            config,
        },
        onResponse
    );

export const webEventsFilters = (
    config?: AxiosRequestConfig,
    onResponse?: Function
) =>
    request(
        types.FILTERS_REQUEST,
        types.FILTERS_FAILED,
        types.FILTERS_SUCCESS,
        "webEventsFilters",
        {
            config,
        },
        onResponse
    );
export const webEventsTableUpdate = (action: string, data: any) => ({
    type: types.TABLE_UPDATE,
    action,
    data,
});

export const webEventsBookingsCreate = (
    data: WebBookingModel,
    onResponse?: Function
) =>
    request(
        types.STORE_REQUEST,
        types.STORE_FAILED,
        types.STORE_SUCCESS,
        "bookingsCreate",
        { data },
        onResponse
    );

export const webEventBookingGet = (
    config?: AxiosRequestConfig,
    onResponse?: Function
) =>
    request(
        types.GET_BOOKING_REQUEST,
        types.GET_BOOKING_FAILED,
        types.GET_BOOKING_SUCCESS,
        "webEventBookingGet",
        {
            config,
        },
        onResponse
    );

export const webEventCheckout = (data: any, onResponse?: Function) => {
    return request(
        types.CHECKOUT_REQUEST,
        types.CHECKOUT_FAILED,
        types.CHECKOUT_SUCCESS,
        "webEventCheckout",
        { data },
        onResponse
    );
};
