import api from "./api";
import types from "./types";
import { request } from "api/apiSaga";
import { call, takeLatest } from "redux-saga/effects";
import { AnyAction } from "redux";

function* ticketReader(action: AnyAction) {
    yield call(request, api.ticketReader, action, "put");
}

export default function* saga() {
    yield takeLatest(types.TICKET_READER_REQUEST, ticketReader);
}
