import { EventModel, IEventTickets } from "app/Events/interfaces";
import { UpsaleModel } from "app/Upsales/interfaces";
import { IForm } from "types/form";

export const statuses = ["DRAFT", "PENDING", "CONFIRMED", "CANCELLED"];
export const statusesCreate = ["DRAFT", "PENDING", "CONFIRMED"];
export const statusesWithoutDraft = ["PENDING", "CONFIRMED", "CANCELLED"];
type StatusesType = "DRAFT" | "PENDING" | "CONFIRMED" | "CANCELLED";
type StatusesWithoutDraftType = "DRAFT" | "PENDING" | "CONFIRMED" | "CANCELLED";
type StatusesTypeCreate = "DRAFT" | "PENDING" | "CONFIRMED";

export type BookingsSendEmailModel = {
    customer_email: string;
    ticket_reservation_time: number;
};

export interface IRefund {
    items: number[];
}
export interface IITemTicket {
    id: number;
    booking_id: number;
    event_ticket_id?: number;
    uuid: string;
    status: string;
    unit_gross: number;
    event_ticket?: IEventTickets[];
}
export interface IITemUpsale {
    id: number;
    booking_id: number;
    uuid: string;
    status: string;
    unit_gross: number;
    upsale_id?: number;
    upsale: UpsaleModel;
}
export interface IItem {
    booking_id: number;
    booking_payment_id: number;
    id: number;
    quantity: number;
    refunded_quantity: number;
    sellable: IITemTicket | IITemUpsale;
    sellable_id: number;
    sellable_type: string;
    unit_gross: number;
}
export type BookingModel = {
    id?: number;
    event_id?: number;
    status?: StatusesType | StatusesTypeCreate | StatusesWithoutDraftType;
    customer_first_name?: string;
    customer_last_name?: string;
    customer_phone?: string;
    customer_email?: string;
    customer_address?: string;
    customer_zip_code?: string;
    customer_city?: string;
    customer_state?: string;
    customer_country?: string;
    customer_language?: string;
    notes?: string;
    tickets?: { event_ticket_id: number; id: number | null }[];
    ip?: string;
    total_gross?: number;
    total_netto?: number;
    total_tax?: number;
    paid_at?: string;
    event?: EventModel;
    currency?: string;
    uuid?: string;
    created_at?: string;
    items?: IItem[];
    event_upsales: { id: number; quantity: number }[];
    refund?: boolean;
};
export interface IBookingsForm extends IForm {
    data: BookingModel;
    isCreate?: boolean;
    status: number;
    onRefund?: Function;
    refundIsLoading?: boolean;
}

export interface IUpdateField {
    item: BookingModel;
    reload: Function;
}
