import queryString from "query-string";

export const addPrefixFilters = (params: any, isFilter: boolean) => {
    return Object.entries(params).reduce((acc: any, [key, value]) => {
        if (isFilter) {
            acc[`event_${key}_filter`] = value;
        } else acc[key] = value;
        return acc;
    }, {});
};

export const removePrefixFilters = (filters: any, isFilter: boolean) => {
    return Object.keys(filters).reduce((newFilters: any, key) => {
        const newKey = isFilter
            ? key.replace(/^event_/, "").replace(/_filter$/, "")
            : key.replace("_", "");
        if (filters[key] !== undefined) {
            newFilters[newKey] = filters[key];
        }
        return newFilters;
    }, {});
};

export const generateParams = (params: any) => {
    const { filters, search } = params;

    const preparedFilters = removePrefixFilters(filters, true);

    const paramsObj = {
        ...preparedFilters,
        ...(search && { search }),
    };
    const queryParams = queryString.stringify(paramsObj, {
        arrayFormat: "bracket",
    });
    return { paramsObj, queryParams };
};
